const styles = theme => ({
  root: {
    padding: 30,
  },
  formControl: {
    backgroundColor: 'white'
  },
  color: {
    color: 'lightgrey'
  }
});

export default styles;