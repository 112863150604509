import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import marketApi from '../../../../services/market';
import { withStyles } from '@material-ui/core/styles';
import CardActionArea from '@material-ui/core/CardActionArea';
import Preloader from '../../../layout/page/preloader';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './styles';
import TextField from '@material-ui/core/TextField';


class ProductsComponent extends Component {
  constructor(props) {
    super(props);

    this.loadingData = this.loadingData.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      search: "",
      loaded: false,
      loadedMore: true,
      products: [],
      page: 1,
      per: 20,
    };
  }

  componentDidMount() {
    this.loadingData();
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  loadingData(page = 1) {
    const {per, search} = this.state;

    let query = "";

    if (search) {
      query += `title=${search}&`
    }

    if (page === 1) {
      this.setState({loaded: false})
    }

    this.setState({loadedMore: false});
    // getfaster - 2
    // oli moli  - 9
    marketApi(`products?${query}per-page=${per}&page=${page}&owner_id=2`)
      .then(json => {
        const products = json.data || [];
        this.setState({
          loaded: true,
          loadedMore: true,
          page,
          products: page === 1 ? products : [
            ...this.state.products,
            ...products,
          ],
        });
      });
  }

  render() {
    const { classes } = this.props;
    const {products, loaded, loadedMore, search} = this.state;

    return (
      <React.Fragment>
        <Grid container>
          <Grid item sm={6} md={6} lg={6}>
            <Typography variant="h4" component="h2">
              Товары
            </Typography>
          </Grid>
          <Grid item sm={6} md={6} lg={6}>
            <TextField
              fullWidth
              id="outlined-name"
              label="Поиск"
              className={classes.textField}
              value={search}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  this.loadingData();
                }
              } }
              onChange={this.handleChange('search')}
              margin="none"
              variant="outlined"
            />
          </Grid>
        </Grid>
        
        <Preloader loaded={loaded}>
          <div className={classNames(classes.layout, classes.cardGrid)}>
            {
              products.length > 0 ? (
                <React.Fragment>
                  <Grid container spacing={40}>
                    {products.map(product => (
                      <Grid item key={product.id} sm={6} md={4} lg={3}>
                        <Card className={classes.card}>
                          <CardActionArea onClick={() => this.props.history.push(`/products/${product.id}`)} className={classes.card}>
                            <CardMedia
                              component="img"
                              height="140"
                              className={classes.cardMedia}
                              title={product.title}
                              image={product.default_photo && product.default_photo.preview}
                            />
                            <CardContent className={classes.cardContent}>
                              <Typography component="p">
                                {product.title}
                              </Typography>
                              <Typography variant="h5" component="h2">
                                {product.price} ₽
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container alignItems={'center'} justify={'center'} className={classes.buttonContainer}>
                    <Grid item>
                      {
                        loadedMore ? (
                          <Button onClick={() => this.loadingData(this.state.page + 1)} variant="contained" color="primary" className={classes.button}>Загрузить еще ...</Button>
                        ) : (
                          <CircularProgress className={classes.progress} size={50} />
                        )
                      }
                      
                    </Grid>
                  </Grid>
                </React.Fragment>
              ) : (
                <Grid container alignItems={'center'} justify={'center'} className={classes.buttonContainer}>
                <Grid item>
                  <Typography variant="h5" component="h5">
                    Ничего не найдено
                  </Typography>
                </Grid>
              </Grid>
              )
            }
            
          </div>
        </Preloader>
      </React.Fragment>
    )
  }
}

ProductsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductsComponent);