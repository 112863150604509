import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

const styles = {
  root: {
    flexGrow: 1,
  },
  buttonContainer: {
    height: '50vh',
  },
};

class Preloader extends Component {
  render() {
    const {loaded, classes, children} = this.props;

    if (loaded) {
      return children;
    }

    return (
      <Grid container alignItems={'center'} justify={'center'} className={classes.buttonContainer}>
        <Grid item>
          <CircularProgress className={classes.progress} size={50} />
        </Grid>
      </Grid>
    )
  }
}

Preloader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Preloader);