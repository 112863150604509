import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import isEqual from 'lodash/isEqual';
import styles from './styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

class PaymentComponent extends Component {
  componentDidMount() {
    this.setWidget(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.cart.order, nextProps.cart.order)) {
      this.setWidget(nextProps)
    }
  }

  setWidget = (props) => {
    const {cart: {order}, handleCancel, handlePayment} = props;

    this.paywidget = new window.PayWidget(
      {
        token: order.token,
        order_id: order.id,
        amount: order.amount,
        // payment: order.paymentType || 'cash',
        apikey: '13a40b48-f997-4345-ae31-410d58bf9b8f',
        // apikey: '0850fd11-5fba-4006-9d3e-9d62857e8161',
      }, 
      handlePayment,
      handleCancel,
    );
  }

  handleChange = (event) => {
    const {handleChange, payment} = this.props;

    handleChange(event);

    if (payment !== event.target.value) {
      this.paywidget.open();
    }

  }
  render() {
    const {classes, payment, paymentType, handleChange} = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h6" gutterBottom>
          Оплата
        </Typography>
        <RadioGroup name="payment" value={payment} onChange={this.handleChange}>
          <FormControlLabel value="cash" className={classes.radio} disabled control={<Radio />} label="Наличный расчет" />
          <FormControlLabel value="card" className={classes.radio} disabled control={<Radio />} label="Оплата банковской картой на дому" />
          <FormControlLabel value="credit" className={classes.radio} disabled control={<Radio />} label="Оплата банковской картой через Интернет" />
          <FormControlLabel value="allibry" className={classes.radio} control={<Radio color="primary"/>} label="Оплата через Allibry Pay"/>
        </RadioGroup>
        {
          payment === 'allibry' && (
            <Button variant="contained" color="primary" onClick={() => this.paywidget.open()}>
              Оплатить
            </Button>
          )
        }
        {
          paymentType && (
            <TextField
              label="Allibry Pay"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={paymentType === 'credit' ? 'Оплата картой' : 'Оплата наличными при получении'}
              onChange={handleChange}
              autoComplete="off"
            />
          )
        }
      </div>
    )
  }
}

PaymentComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PaymentComponent);