import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import HeaderComponent from './components/layout/header';
import SidebarComponent from './components/layout/sidebar';
import ContentComponent from './components/layout/content';
import {BrowserRouter} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';
import {store, persistor} from './store';


const styles = () => ({
  root: {
    display: 'flex',
  },
});

class App extends Component {
  constructor(props) {
    super(props);

    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);

    this.state = {
      open: false,
    };
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {classes} = this.props;
    const {open} = this.state;

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <React.Fragment>
              <CssBaseline />
              <div className={classes.root}>
                <HeaderComponent open={open} handleDrawerOpen={this.handleDrawerOpen}/>
                <SidebarComponent open={open} handleDrawerClose={this.handleDrawerClose}/>
                <ContentComponent/>
              </div>
            </React.Fragment>
          </BrowserRouter>
        </PersistGate>
      </Provider>
      
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
