import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styles from './styles';
import Typography from '@material-ui/core/Typography';

class ProductsComponent extends Component {

  render() {
    const {classes, cart, cost} = this.props;

    return (
      <div className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Товар</TableCell>
              <TableCell>Цена</TableCell>
              <TableCell>Количество</TableCell>
              <TableCell>Всего</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              cart.products.map(product => {
                return (
                  <TableRow key={product.id}>
                    <TableCell className={classes.colImage}>
                      <img alt="No" src={product.default_photo && product.default_photo.preview} className={classes.img}/>
                    </TableCell>
                    <TableCell className={classes.colTitle}>{product.title}</TableCell>
                    <TableCell className={classes.colPrice}>{product.price} руб.</TableCell>
                    <TableCell className={classes.colCount}>{product.count}</TableCell>
                    <TableCell className={classes.colTotal}>{product.price * product.count} руб.</TableCell>
                  </TableRow>
                )
              })
            }
            <TableRow className={classes.total}>
              <TableCell colSpan={4}>
                <Typography variant="subtitle1" gutterBottom align="right">
                  Сумма без скидки
                </Typography>
                <Typography variant="subtitle1" gutterBottom align="right">
                  Скидка
                </Typography>
                <Typography variant="h6" gutterBottom align="right">
                  Итого:
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="subtitle1" gutterBottom>
                  {cost} руб.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  0 руб.
                </Typography>
                <Typography variant="h6" gutterBottom>
                  {cost} руб.
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        
      </div>
    )
  }
}

ProductsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductsComponent);