const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'white',
  },
  content: {

  },
  sidebar: {
    backgroundColor: '#f3efea' 
  }
});

export default styles; 