import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

class PointComponent extends Component {
  render() {
    const {classes, deliveryDate, deliveryTimeFrom, deliveryTimeTo, deliveryPointName, description} = this.props;

    return (
      <div className={classes.root}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              label="Пункт выдачи"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={deliveryPointName}
              autoComplete="off"
            />
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label="Дата доставки"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={deliveryDate && moment(deliveryDate).format('DD.MM.YYYY')}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label="Время доставки"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={`${deliveryTimeFrom && deliveryTimeTo && `${moment(deliveryTimeFrom).format("HH:mm")} - ${moment(deliveryTimeTo).format('HH:mm')}`}`}
              autoComplete="off"
            />
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              label="Комментарий к заказу"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={description}
              multiline
              rows={3}
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

PointComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PointComponent);