import {
  SET_CART_ORDER,
  ADD_PRODUCT_CART, 
  REMOVE_PRODUCT_CART, 
  CHANGE_PRODUCT_COUNT, 
  CHANGE_PRODUCT_CHECKED,
  REMOVE_CART,
} from '../constants';

const initialState = {
  products: [],
  order: null,
};

const cart = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART_ORDER:
      return {
        ...state,
        order: action.payload,
      };
      
    case ADD_PRODUCT_CART:
      
      const newProducts = Object.assign([], state.products);
      const productIndex = state.products.findIndex(product => product.id === action.payload.id);

      if (productIndex > -1) {
        newProducts[productIndex].count += 1;
      } else {
        action.payload.count = 1;
        newProducts.push(action.payload);
      }

      return {
        ...state,
        products: newProducts,
      };

    case REMOVE_PRODUCT_CART:
      return {
        ...state,
        products: state.products.filter(p => p.id !== action.payload.id),
      };

    case REMOVE_CART:
      return initialState;

    case CHANGE_PRODUCT_COUNT:
      const products = Object.assign([], state.products);
      const index = state.products.findIndex(pr => pr.id === action.payload.product.id);
      if (index !== -1) {
        let count = products[index].count + action.payload.delta;

        if (count === 0) {
          products.splice(index, 1);
        } else {
          products[index].count = count;
        }
      }

      return {
        ...state,
        products,
      };

    case CHANGE_PRODUCT_CHECKED:
      return {
        ...state,
        products: state.products.map(p => {

          if (p.id === action.payload.product.id) {
            p.checked = !p.checked;
          }
          return p;
        }),
      };

    default:
      return state;
  }

}

export default cart;
