import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CourierComponent from './courier';
import PointComponent from './point';
import Button from '@material-ui/core/Button';

class DeliveryComponent extends Component {
  componentDidMount() {
    this.setWidget(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.cart !== nextProps.cart) {
      this.setWidget(nextProps)
    }
  }

  handleChange = (event) => {
    const {handleChange, type} = this.props;

    handleChange(event);

    if (type !== event.target.value) {
      this.widget.open();
    }
    
  }

  setWidget = (props) => {
    const {cart, handleDelivery} = props;

    this.widget = new window.DeliveryWidget(
      {
        products: cart.products, 
        apikey: '13a40b48-f997-4345-ae31-410d58bf9b8f',
        // apikey: '0850fd11-5fba-4006-9d3e-9d62857e8161',
        order: cart.order,
      }, 
      handleDelivery,
    );
  }

  render() {
    const {
      classes, 
      deliveryType, 
      type, 
      description,
      address,
      cart,
      deliveryDate,
      deliveryTimeFrom,
      deliveryTimeTo,
      deliveryPointName,
      handleChange,
    } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h6" gutterBottom>
          Доставка
        </Typography>
        <RadioGroup name="type" value={type} onChange={this.handleChange}>
          <FormControlLabel value="courier" className={classes.radio} disabled control={<Radio />} label="Курьерская доставка" />
          <FormControlLabel value="point" className={classes.radio} disabled control={<Radio />} label="Пункты выдачи" />
          <FormControlLabel value="pochta" className={classes.radio} disabled control={<Radio />} label="Почта России" />
          <FormControlLabel value="allibry" className={classes.radio} control={<Radio color="primary"/>} label="Сервис Allibry"/>
        </RadioGroup>
        {
          type === 'allibry' && (
            <Button variant="contained" color="primary" onClick={() => this.widget.open()}>
              {cart.order ? 'Изменить' : 'Установить'} параметры доставки allibry
            </Button>
          )
        }
        {
          deliveryType !== 'deliveryToPoint' ?  (
            <CourierComponent
              address={address}
              description={description}
              deliveryDate={deliveryDate}
              deliveryTimeFrom={deliveryTimeFrom}
              deliveryTimeTo={deliveryTimeTo}
              handleChange={handleChange}
            />
          ) : (
            <PointComponent
              description={description}
              deliveryDate={deliveryDate}
              deliveryTimeFrom={deliveryTimeFrom}
              deliveryTimeTo={deliveryTimeTo}
              deliveryPointName={deliveryPointName}
            />
          )
        }
      </div>
    )
  }
}

DeliveryComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DeliveryComponent);