const styles = theme => ({
  root: {    
    paddingBottom: 20,
  },
  total: {
    backgroundColor: '#f3efea' ,
    height: 100,
  },
  colImage: {
    padding: 10,
  },
  colTitle: {
    width: '30%'
  },
  colPrice: {
    width: '20%'
  },
  colCount: {
    width: '20%'
  },
  colTotal: {
    width: '20%'
  },
  img: {
    padding: 10,
    height: 80,
    width: 80,
  },
});

export default styles;