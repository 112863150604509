const styles = theme => ({
  root: {
    // 
    flexGrow: 1,
  },
  slideContainer: {
    flexDirection: 'column',
    maxWidth: 400,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
  card: {
    display: 'flex',
  },
  button: {
    margin: theme.spacing.unit,
  },
  details: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    objectFit: 'contain',
    padding: 20,
    flex: 1
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
});

export default styles; 