const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  buttonContainer: {
    height: 150,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    // padding: `${theme.spacing.unit * 8}px 0`,
    paddingTop: '20px'
  },
  card: {
    height: '100%',
  },
  cardMedia: {
    objectFit: 'contain',
    padding: 20,
  },
  cardContent: {
    flexGrow: 1,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

export default styles; 