import baseUri from './config';

function marketApi(endPoint, payload = {}, method = 'GET', headers = {}, token = null) {
  let params = {
    method,
  };

  let newHeaders = new Headers(headers);
  newHeaders.append('Content-Type', 'application/json');
  // newHeaders.append('Authorization', `Bearer ${userSelector.get().token}`);
  newHeaders.append('Authorization', `Bearer ${token ? token : '0TZxKoetg7QqMdU6xhnozGiA65N2NgYq'}`);
  
  params.headers = newHeaders;  

  if (method !== 'GET') {
    params.body = JSON.stringify(payload);
  }

  return fetch(`${baseUri.url}${endPoint}`, params)
    .then(response => {

      if (response.status === 401) { throw new Error("Неверный логин/пароль"); }
      if (response.status !== 200 && response.status !== 204) { throw new Error(); }

      return response.json();
    })
    .then(json => {

      if (!json.success) {
        throw json.description;
      }

      return json;
    })
    .catch((e) => {
      throw e.toString();
    });
}

export default marketApi;
