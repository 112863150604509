import React, {Component} from 'react';
import {connect} from 'react-redux';
import {addProductCart} from '../../../../redux/actions/cart';
import marketApi from '../../../../services/market';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Preloader from '../../../layout/page/preloader';
import Button from '@material-ui/core/Button';
import styles from './styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@material-ui/core/MobileStepper';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class ProductComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      product: {},
      activeStep: 0,
    };
  }

  componentDidMount() {
    const {id} = this.props.match.params;
    marketApi(`products/${id}`)
      .then(json => {
        const product = json.data || {};
        this.setState({
          loaded: true,
          product,
        });
      });
  }

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };


  render() {
    const {product, loaded, activeStep} = this.state;
    const { classes, theme } = this.props;
    const maxSteps = (product.images || []).length;

    return (
      <Preloader loaded={loaded}>
        <div className={classes.root}>
          <Card className={classes.card}>
            <div className={classes.slideContainer}>
            <AutoPlaySwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={this.handleStepChange}
              enableMouseEvents
            >
              {(product.images || []).map((step, index) => (
                <div key={step.url}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <img className={classes.img} src={step.url} alt={step.label} />
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              className={classes.mobileStepper}
              nextButton={
                <Button size="small" onClick={this.handleNext} disabled={activeStep === maxSteps - 1}>
                  Вперед
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              }
              backButton={
                <Button size="small" onClick={this.handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  Назад
                </Button>
              }
            />
            </div>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography component="h5" variant="h5">
                  {product.title}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {product.description}
                </Typography>
                <Typography variant="h4" component="h4">
                  {product.price} ₽
                </Typography>
              </CardContent>
              <div className={classes.controls}>
                <Button 
                  onClick={() => {
                    this.props.addProductCart(product);
                    this.props.history.push('/basket');
                  }}
                  size={'large'}
                  variant="contained" 
                  color="primary" 
                  className={classes.button}
                >
                  Купить
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Preloader>
    )
  }
}

ProductComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  ({
    addProductCart(product) {
      dispatch(addProductCart(product));
    },
  });

export default connect(null, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ProductComponent));