import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import marketApi from '../../../../services/market';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Preloader from '../../../layout/page/preloader';

class CategoriesComponent extends Component {
  constructor(props) {
    super(props);

    this.loadingData = this.loadingData.bind(this);

    this.state = {
      loaded: false,
      categories: [],
    };
  }

  componentDidMount() {
    this.loadingData();
  }

  loadingData() {
    this.setState({loaded: false});

    marketApi(`categories`)
      .then(json => {
        const result = json.data || {};
        const categories = result.categories || [];

        this.setState({
          categories,
          loaded: true,
        });
      })
  }

  render() {
    const {classes } = this.props;
    const {categories, loaded} = this.state;

    return (
      <React.Fragment>
        <Typography variant="h4" component="h2">
          Категории
        </Typography>
        <Preloader loaded={loaded}>
          <div className={classNames(classes.layout, classes.cardGrid)}>
            <Grid container spacing={40}>
              {categories.map(category => (
                <Grid item key={category.id} sm={6} md={4} lg={3}>
                  <Card className={classes.card}>
                    <CardActionArea onClick={() => this.props.history.push(`/categories/${category.id}`)} className={classes.card}>
                      <CardMedia
                        component="img"
                        height="140"
                        className={classes.cardMedia}
                        title={category.name}
                        image={category.file}
                      />
                      <CardContent className={classes.cardContent}>
                        <Typography component="p">
                          {category.name}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </Preloader>
      </React.Fragment>
    )
  }
}

CategoriesComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CategoriesComponent);