import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';

class CategoryComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      category: {},
    };
  }

  loadingData = () => {

  }

  componentDidMount() {
    this.loadingData();
  }
  
  render() {
    return (
      <React.Fragment>
        <Typography variant="h4" component="h2">
          Категория
        </Typography>
      </React.Fragment>
    )
  }
}

export default CategoryComponent;