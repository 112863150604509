const styles = theme => ({
  root: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  radio: {},
  delivery: {
    marginTop: 0,
    marginBottom: 0,
  }
});

export default styles;