import React, {Component} from 'react';
import marketApi from '../../../services/market';

class TestComponent extends Component {
  componentDidMount() {
    marketApi('users/qr')
      .then(json => {
      })
      .catch(e => {
      })
  }
  render() {
    return (
      <div>Test</div>
    )
  }
}

export default TestComponent;