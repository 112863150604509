import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import ProductsComponent from '../products';
import DeliveryComponent from '../delivery';
import PaymentComponent from '../payment';

class ContentComponent extends Component {
  render() {
    const {
      classes, 
      type, 
      deliveryType, 
      payment,
      paymentType,
      address,
      description,
      deliveryDate,
      deliveryPointName,
      deliveryTimeFrom,
      deliveryTimeTo,
      cost,
      handleChange,
      cart,
      handleDelivery,
      handleCancelPayment, 
      handleSuccessPayment,
    } = this.props;

    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <ProductsComponent cost={cost} cart={cart}/>
          </Grid>
        </Grid>
        <hr/>
        <Grid container>
          <Grid item xs={12}>
            <DeliveryComponent
              cart={cart}
              type={type}
              address={address}
              deliveryPointName={deliveryPointName}
              description={description}
              deliveryDate={deliveryDate}
              deliveryTimeFrom={deliveryTimeFrom}
              deliveryTimeTo={deliveryTimeTo}
              deliveryType={deliveryType}
              handleChange={handleChange}
              handleDelivery={handleDelivery}
            />
          </Grid>
        </Grid>
        {
          cart.order && (
            <React.Fragment>
              <hr/>
              <Grid container>
                <Grid item xs={12}>
                  <PaymentComponent 
                    payment={payment}
                    paymentType={paymentType}
                    cart={cart}
                    handleCancel={handleCancelPayment}
                    handleChange={handleChange}
                    handlePayment={handleSuccessPayment}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          )
        }
        
      </div>
    )
  }
}

ContentComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContentComponent);