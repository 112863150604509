import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import moment from 'moment';

class CourierComponent extends Component {
  render() {
    const {
      classes, 
      address,
      description, 
      deliveryDate,
      deliveryTimeFrom,
      deliveryTimeTo,
    } = this.props;

    const {city, street, house, apartment} = address;

    return (
      <div className={classes.root}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label="Город"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={city}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label="Улица"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={street}
              autoComplete="off"
            />
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label="Дом"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={house}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label="Квартира"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={apartment}
              autoComplete="off"
            />
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label="Дата доставки"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={deliveryDate && moment(deliveryDate).format('DD.MM.YYYY')}
              autoComplete="off"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              label="Время доставки"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={`${deliveryTimeFrom && deliveryTimeTo && `${moment(deliveryTimeFrom).format("HH:mm")} - ${moment(deliveryTimeTo).format('HH:mm')}`}`}
              autoComplete="off"
            />
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              label="Комментарий к заказу"
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={description}
              multiline
              rows={3}
              autoComplete="off"
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

CourierComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CourierComponent);