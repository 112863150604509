import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ProductsComponent from '../../pages/products/all';
import ProductComponent from '../../pages/products/show';
import CategoriesComponent from '../../pages/categories/all';
import CategoryComponent from '../../pages/categories/show';
// import CartComponent from '../../pages/cart';
import BasketComponent from '../../pages/basket';
import TestComponent from '../../pages/test';
import {ToastContainer} from 'react-toastify';
import {Route} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const styles = theme => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
  tableContainer: {
    height: 320,
  },
});

class ContentComponent extends Component {
  render() {
    const {classes} = this.props;

    return (
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Route exact path='/' component={ProductsComponent}/>
        <Route exact path='/test' component={TestComponent}/>
        <Route path='/products/:id' component={ProductComponent}/>
        <Route exact path='/products' component={ProductsComponent}/>
        <Route path='/categories/:id' component={CategoryComponent}/>
        <Route exact path='/categories' component={CategoriesComponent}/>
        <Route path='/basket' component={BasketComponent}/>
        <ToastContainer/>
      </main>
    )
  }
}

ContentComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContentComponent);