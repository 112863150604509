import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

class SidebarComponent extends Component {
  render() {
    const {classes, name, phone, cart, email, cost, deliveryCost, handleChange, handleSubmit} = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h6" gutterBottom>
          Оформление заказа
        </Typography>
        <TextField
          label="Ваши имя и фамилия"
          placeholder="Введите ваше имя и фамилию"
          fullWidth
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          value={name}
          onChange={handleChange}
          autoComplete="off"
          className={classes.formControl}
        />
        <TextField
          label="Ваш телефон"
          placeholder="+7 (123) 456-78-90"
          fullWidth
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          value={phone}
          onChange={handleChange}
          autoComplete="off"
          className={classes.formControl}
        />
        <TextField
          label="Ваш e-mail"
          placeholder="Введите ваш e-mail"
          fullWidth
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          value={email}
          onChange={handleChange}
          autoComplete="off"
          className={classes.formControl}
        />
        <hr/>
        <Grid container spacing={16}>
          <Grid item xs={6} md={6} lg={6} justify='flex-end' container>
            <Typography variant="subtitle1" gutterBottom color="textSecondary">
              Сумма без скидки
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6} justify='flex-start' container>
            <Typography variant="subtitle1" gutterBottom color="textSecondary">
              {cost} руб.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={6} md={6} lg={6} justify='flex-end' container>
            <Typography variant="subtitle1" gutterBottom color="textSecondary">
              Скидка
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6} justify='flex-start' container>
            <Typography variant="subtitle1" gutterBottom color="textSecondary">
              0 руб.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={6} md={6} lg={6} justify='flex-end' container>
            <Typography variant="subtitle1" gutterBottom color="textSecondary">
              Доставка
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6} justify='flex-start' container>
            <Typography variant="subtitle1" gutterBottom color="textSecondary">
              {deliveryCost} руб.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={6} md={6} lg={6} justify='flex-end' container>
            <Typography variant="subtitle1" gutterBottom color="textSecondary">
              Итого
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} lg={6} justify='flex-start' container>
            <Typography variant="subtitle1" gutterBottom color="textSecondary">
              {cost + deliveryCost} руб.
            </Typography>
          </Grid>
        </Grid>
        {/* <hr/>
        <Button variant="contained" size="large" disabled={!cart.order || !cart.order.paymentType} color="primary" fullWidth onClick={handleSubmit}>
          Оформить заказ
        </Button> */}
      </div>
    )
  }
}

SidebarComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SidebarComponent);