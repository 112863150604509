import storage from 'redux-persist/lib/storage'
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from '../redux/reducers';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import logger from 'redux-logger';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart'],
  stateReconciler: autoMergeLevel2,
};

export const store = createStore(
  persistReducer(persistConfig, rootReducer),
  applyMiddleware(logger, thunk)
);
export const persistor = persistStore(store);
