import {
  SET_CART_ORDER,
  ADD_PRODUCT_CART, 
  REMOVE_PRODUCT_CART, 
  CHANGE_PRODUCT_COUNT, 
  CHANGE_PRODUCT_CHECKED,
  REMOVE_CART,
} from '../constants';

export const setCartOrder = (order) => 
  ({
    type: SET_CART_ORDER,
    payload: order,
  })
  
export const addProductCart = (product) =>
  ({
    type: ADD_PRODUCT_CART,
    payload: product,
  });

export const removeProductCart = (product) =>
  ({
    type: REMOVE_PRODUCT_CART,
    payload: product,
  })

export const changeProductCount = (product, delta) =>
  ({
    type: CHANGE_PRODUCT_COUNT,
    payload: { product, delta },
  });

export const removeAll = () =>
  ({
    type: REMOVE_CART,
  })

export const changeProductChecked = (product, checked) =>
  ({
    type: CHANGE_PRODUCT_CHECKED,
    payload: { product, checked },
  })
