import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {setCartOrder, removeAll} from '../../../redux/actions/cart';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import ContentComponent from './components/content';
import SidebarComponent from './components/sidebar';
import Typography from '@material-ui/core/Typography';
import {toast} from 'react-toastify';
import marketApi from '../../../services/market';

class BasketComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      phone: '',
      email: '',
  
      type: '',
      deliveryType: 'deliveryToDoor',
      address: {},
      description: "",
      deliveryPointId: null,
      deliveryPointName: "",

      deliveryDate: "",
      deliveryTimeFrom: "",
      deliveryTimeTo: "",
  
      payment: '',
      paymentType: '',
  
      deliveryCost: 0,
      ...this.initState(props),

      loaded: true,
    }
  }
  

  initState = (props) => {
    if (!props.cart.order) {
      return {};
    }

    return props.cart.order;
  }

  handleChange = (event) => {
    const {name, value} = event.target;
    this.setState({[name]: value});
  }

  handleCancelPayment = () => {
    this.props.setCartOrder({...this.props.cart.order, draft: false});
  }

  handleSuccessPayment = (params) => {
    toast.success('Заказ успешно оформлен');
    this.props.setCartOrder({draft: false})
    this.props.removeAll();
  }

  handleDelivery = (params) => {
    const {address, name, email, phone, deliveryCost, deliveryPointId, deliveryPointName, deliveryType, deliveryDate, deliveryTimeFrom, deliveryTimeTo, description} = params;

    this.props.setCartOrder({...this.props.cart.order, ...params, type: 'allibry', draft: true});

    this.setState({
      address,
      name,
      phone,
      email,
      deliveryCost,
      deliveryType,
      deliveryPointId,
      deliveryPointName,
      deliveryDate,
      deliveryTimeFrom,
      deliveryTimeTo,
      description,
    });
  }

  totalCost = () => {
    const {products} = this.props.cart;
    const reducer = (sum, product) => sum + product.price * product.count;

    return products.reduce(reducer, 0);
  }

  handleSubmit = () => {
    let agree = window.confirm('После подтверждения заказа Вы не сможете поменять состав заказа и изменить условия заказа. Вы подтверждаете заказ?');

    if (!agree) {
      return;
    }

    const {cart: {order}} = this.props;

    this.setState({loaded: false});
    marketApi(`orders/${order.id}`, {draft: false, payment_type: order.paymentType}, 'PUT', {}, order.token)
      .then(json => {
        const result = json.data || {};
        const {draft, guids} = result;
        const order_guid = draft ? '' : guids[0].guid;

        if (order.paymentType === 'credit') {
          // this.handleCredit(order_guid);
        } else {
          toast.success('Заказ успешно оформлен');
          this.props.setCartOrder({draft: false})
          this.props.removeAll();
        }

        this.setState({loaded: true});
      })
      .catch(e => {
        toast.error(e);
        this.setState({loaded: true});
      });
  }

  render() {
    const {classes, cart} = this.props;
    const {
      name,
      phone,
      email,
      type,
      deliveryType,
      address,
      description,
      deliveryCost,
      deliveryDate,
      deliveryTimeFrom,
      deliveryTimeTo,
      deliveryPointName,
      payment,
      paymentType,
      loaded,
    } = this.state;

    const cost = this.totalCost();

    return (
      <React.Fragment>
        <Typography variant="h4" gutterBottom>
          Оформление заказа
        </Typography>
        {
          cart.products.length > 0 ? (
            <div className={classes.root}>
              <Grid container>
                <Grid item xs={12} md={8} lg={8} className={classes.content}>
                  <ContentComponent 
                    cost={cost}
                    type={type} 
                    cart={cart}
                    payment={payment}
                    paymentType={paymentType}
                    deliveryType={deliveryType}
                    deliveryDate={deliveryDate}
                    deliveryPointName={deliveryPointName}
                    deliveryTimeFrom={deliveryTimeFrom}
                    deliveryTimeTo={deliveryTimeTo}
                    description={description}
                    address={address}
                    handleDelivery={this.handleDelivery}
                    handleChange={this.handleChange}
                    handleSuccessPayment={this.handleSuccessPayment}
                    handleCancelPayment={this.handleCancelPayment}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={4} className={classes.sidebar}>
                  <SidebarComponent
                    name={name}
                    phone={phone}
                    email={email}
                    cost={cost}
                    cart={cart}
                    deliveryCost={deliveryCost}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                  />
                </Grid>
              </Grid>
            </div>
          ) : (
            <Typography variant="h6" align="center" gutterBottom>
              Корзина пуста
            </Typography>
          )
        }
        
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => 
  ({
    cart: state.cart,
  });

const mapDispatchToProps = dispatch =>
  ({
    setCartOrder(order) {
      dispatch(setCartOrder(order));
    },
    removeAll() {
      dispatch(removeAll());
    },
  });

BasketComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BasketComponent));